import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { Link } from "gatsby"
import { List, ListItem } from "@mui/material"
import * as styles from '../styles/search.module.scss'

const SearchBar = ({ searchQuery, setSearchQuery, results }) => {
    const formatDirectory = (directory) => {
        return directory.replace('-', ' ').charAt(0).toUpperCase() + directory.slice(1);
    }

    const resetSearch = () => {
        setSearchQuery('');
    }

    return (
        <>
            <div className={styles.searchContainer}>
                <div className={styles.searchIconWrapper}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search lessons…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchQuery}
                    onInput={(e) => setSearchQuery(e.target.value)}
                    type="text"
                    id="header-search"
                    name="s"
                    autoComplete='off'
                    className={`${styles.searchInput} ${results.length > 0 && styles.searchInputActive}`}
                />
            </div>
            {results.length > 0 && <div className={styles.results}>
                <div className={styles.resultsWrapper}>
                    <List style={{padding: 0}}>
                        {results.map((result, index) => (
                            <ListItem key={`result-${index}`} className={styles.resultListItem}>
                                <Link onClick={resetSearch} to={`/courses/${result.directory}/${result.slug}/`} className={styles.resultLink}>
                                    <div className={styles.resultContainer}>
                                        <span>{result.title}</span>
                                        <span>Course - {formatDirectory(result.directory)}</span>
                                    </div>
                                </Link>
                            </ListItem>))
                        }
                    </List>
                </div>
            </div>}
            {results.length === 0 && searchQuery !== '' && <div className={styles.results}>
                <div className={styles.noResultsWrapper}>
                    <span>No results</span>   
                </div>
            </div>}
        </>
    )
}

export default SearchBar;