/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React, { useState } from "react"
import { AppBar, Typography, ButtonBase } from "@mui/material"
import { Link } from "gatsby"
import { isPast } from "date-fns"
import * as styles from '../styles/news-banner.module.scss';

const NewsBannerStyled = props => {

  const [isNewVisible, setIsNewVisible] = useState(true)

  const closeNews = () => { setIsNewVisible(false) }

  const displayUntilDate = new Date(2023, 1, 20)
  const newIsValid = !isPast(displayUntilDate)

  const targetLink = "/games"
  const news = "Test your Computer Science knowledge by playing our games!"

  return (
    <AppBar position="fixed" className={newIsValid && isNewVisible ? `${styles.newsBar}` : `${styles.hideBar}`}  >
      <ButtonBase component={Link} to={targetLink} className={styles.newsText} ><Typography variant="body" >{news}</Typography></ButtonBase>
      <ButtonBase className={styles.closeNewsButton} onClick={closeNews} >X</ButtonBase>
    </AppBar>
  );
}

export default NewsBannerStyled
