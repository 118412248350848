import CodeBlock from "src/components/common/code-block";
import CreateAVariable from "src/components/python/create-a-variable";
import ToggleTrinketButton from "src/components/common/toggle-trinket-button";
import CustomAccordion from "src/components/accordion";
import * as React from 'react';
export default {
  CodeBlock,
  CreateAVariable,
  ToggleTrinketButton,
  CustomAccordion,
  React
};