/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from "react"
import Clue from "./clue"

class Clues extends React.Component {
  constructor(props) {
    super(props)
    const cluesAcross = []
    const cluesDown = []

    for (const key in this.props.clues) {
      const clue = this.props.clues[key]
      clue.id = key
      if (clue.direction === "across") {
        cluesAcross.push(clue)
      } else {
        cluesDown.push(clue)
      }
    }

    this.state = {
      across: cluesAcross,
      down: cluesDown,
    }
  }

  render() {
    return (
      <div>
        <div>
          Down
          <ol className="clue-list">
            {this.state.down.map(clueData => (
              <Clue
                key={clueData.id}
                clueID={clueData.id}
                clueText={clueData.clue}
                clueNumber={clueData.number}
                clueBoxes={clueData.boxes}
                setActiveClueBoxes={this.props.setActiveClueBoxes}
                setActiveClue={this.props.setActiveClue}
                isActive={this.props.activeClue.indexOf(clueData.id) > -1}
                setBoxInFocus={this.props.setBoxInFocus}
              />
            ))}
          </ol>
        </div>
        <div>
          Across
          <ol className="clue-list">
            {this.state.across.map(clueData => (
              <Clue
                key={clueData.id}
                clueID={clueData.id}
                clueText={clueData.clue}
                clueNumber={clueData.number}
                clueBoxes={clueData.boxes}
                setActiveClueBoxes={this.props.setActiveClueBoxes}
                setActiveClue={this.props.setActiveClue}
                isActive={this.props.activeClue.indexOf(clueData.id) > -1}
                setBoxInFocus={this.props.setBoxInFocus}
              />
            ))}
          </ol>
        </div>
      </div>
    )
  }
}

export default Clues
