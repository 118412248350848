/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import { useRef, useEffect } from 'react'

const useCanvas = (draw) => {
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        draw(context)
        
    }, [draw])

    return canvasRef
}

export default useCanvas