/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from "react"
import TecKnoHeroIllustration from '../assets/images/TecKnoHeroIllustration.png'

const SEO_DATA = {
  description: 'Learn computer science with  fun games and coding adventures for free. TecKno is an educational website for studying second level computing in Ireland.',
  title: 'TecKno | Computer Science For Everyone',
  url: 'https://teckno.dell.com/',
  keywords: ['Computing', 'Leaving Certificate', 'Junior Certificate', 'Education', 'course', 'lesson', 'learning'],
};

function Seo({ url, title, description, children }) {
  return (
    <>
      <script>{`
        Dell = window.Dell || {};
        Dell.Metrics = Dell.Metrics || {};
        Dell.Metrics.sc = Dell.Metrics.sc || {};
        Dell.Metrics.sc.country = "us";         /***dynamic value based on site***/
        Dell.Metrics.sc.language = "en";      /***dynamic value based on site***/
        Dell.Metrics.sc.segment = "corp";      /**dynamic based on site area**/
        Dell.Metrics.sc.customerset = "";  /***dynamic based on site area***/
        Dell.Metrics.sc.cms = "teckno"; 
        Dell.Metrics.sc.pagename = "us|en|corp|teckno|home|index";  /***relevant unique page name ***/
        Dell.Metrics.sc.applicationname = "teckno"; /***relevant application name***/`}
      </script>
      <script type="text/javascript" src="//nexus.dell.com/dell/teckno/Bootstrap.js"></script>
      
      <meta property="og:title" content={title ? title + " | TecKno" : SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url ? url : SEO_DATA.url} />
      <meta property="og:description" content={description ? description : SEO_DATA.description} />
      <meta property="og:image" content={TecKnoHeroIllustration} />
      <meta property="og:image:width" content="76" />
      <meta property="og:image:height" content="76" />
      <meta name="cache-control" content="public, max-age=0, must-revalidate" />
      <meta name="description" content={description ? description : SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0"></meta>

      <title>{title ? title + " | TecKno" : SEO_DATA.title}</title>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link rel="icon" href="assets/images/site_icon.png" sizes="246x246" />
      <link rel="apple-touch-icon" href="assets/images/site_icon.png" />
      <link rel="apple-touch-icon" sizes="246x246" href="assets/images/site_icon.png" />
      <html lang="en" dir="ltr" />
      {children}
    </>
  )
}

export default Seo;
