/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from "react"
import Confetti from "react-confetti"
import { Paper, Button, TextField } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import * as styles from '../../styles/create-a-variable.module.scss';

const CreateAVariable = () => {
  const variableName = "myNumber"
  const variableValue = 6

  const hints = ["", "name = value", "myNumber = ", "myNumber = 6"]

  const buttons = [
    {
      type: "hint",
      text: "Show Hint",
      variant: "text",
    },
    {
      type: "hint",
      text: "Next Hint",
      variant: "text",
    },
    {
      type: "answer",
      text: "Show Answer",
      variant: "contained",
    },
    {
      type: "reset",
      text: "Reset",
      variant: "contained",
    },
  ]

  const [showConfetti, setShowConfetti] = React.useState(false)
  const [hint, setHint] = React.useState(hints[0])
  const [answer, setAnswer] = React.useState("")
  const [button, setButton] = React.useState({
    variant: "text",
    text: "Show Hint",
  })

  const handleChange = event => {
    setAnswer(event.target.value)

    if (
      event.target.value.replace(/ /g, "") ===
      `${variableName}=${variableValue}`
    ) {
      setShowConfetti(true)
      setButton(buttons.find(button => button.type === "reset"))
    } else {
      setShowConfetti(false)
    }
  }

  const handleClick = () => {
    switch (button.type) {
      case "reset":
        setHint(hints[0])
        setAnswer("")
        setShowConfetti(false)
        return setButton(buttons[0])
      case "answer":
        setHint(hints[0])
        setAnswer(`${variableName} = ${variableValue}`)
        return setButton(buttons.find(button => button.type === "reset"))
      default:
        const hintIndex = hints.indexOf(hint)
        setHint(hints[hintIndex + 1])

        if (hints[hintIndex + 2]) {
          return setButton(buttons.find(button => button.text === "Next Hint"))
        } else {
          return setButton(
            buttons.find(button => button.text === "Show Answer")
          )
        }
    }
  }

  return (
    <Paper className={styles.paperContainer} id="paper-container">
      {showConfetti ? (
        <Confetti gravity={Number(0.5)} recycle={false} />
      ) : (
        <></>
      )}

      <h2>Try It</h2>
      <p>
        Create a variable named <b>{variableName}</b> and give it a value of{" "}
        <b>{variableValue}</b>.
      </p>
      <div className={styles.inputArea}>
        <TextField
          id="outlined"
          label="Code"
          InputLabelProps={{
            shrink: true,
          }}
          helperText={hint}
          value={answer}
          variant="outlined"
          onChange={handleChange}
        />
        <div className={styles.buttonArea}>
          <Button
            className={styles.helperBtn}
            color="primary"
            variant={button.variant}
            onClick={() => {
              handleClick()
            }}
          >
            {button.text}
          </Button>
          {showConfetti && (
            <CheckCircleIcon
              fontSize="large"
              style={{ color: "green", paddingLeft: "10px" }}
            />
          )}
        </div>
      </div>
    </Paper>
  )
}

export default CreateAVariable
