/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React, { useState } from "react"
import { createTheme } from '@mui/material/styles';
import { StyledEngineProvider, ThemeProvider, CssBaseline } from "@mui/material";

import AppBarStyled from "src/components/app-bar"
import NewsBannerStyled from "src/components/news-banner"
import Seo from 'src/components/seo';
import tecknoTheme from "src/styles/theme.json"
import DrawerSideNav from "src/components/app-nav-mobile"
import Footer from "src/components/footer"
import 'src/styles/typography.css'
import ScrollTopButton from 'src/components/common/scroll-top-button';
import NavData from "../assets/data/navigation-data.yaml"
import * as styles from '../styles/index.module.scss';

const theme = createTheme(tecknoTheme)

function Layout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBarStyled navData={NavData} handleDrawerToggle={handleDrawerToggle} />
        <NewsBannerStyled />
        <div>
            <DrawerSideNav
              handleDrawerToggle={handleDrawerToggle}
              mobileOpen={mobileOpen}
              navData={NavData}
            />

            <main className={styles.content}>
                {children}
            </main>

            <Footer/>
            <ScrollTopButton/>
        </div>

      </ThemeProvider>
    </StyledEngineProvider>
  );
}


export default Layout