/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import * as styles from '../../styles/bubble-sort.module.scss';

import Canvas from './canvas'
import Timer from './timer'


const InsertSortAnimation = () => {
    const initialBarState = [85, 22, 55, 66, 77, 90, 10, 25, 33, 5]

    const [bars, setBars] = useState(initialBarState)
    const [timerVisible, setTimerVisible] = useState(false)
    const [sorting, setSorting] = useState(false)
    const [ctx, setCtx] = useState()

    const bottom = 400
    const barWidth = 40
    const spaceBetweenBars = 10

    let locationMarker = 0
    let inset = 0
    let iIndexXPos = 30

    const draw = (ctx) => {
        let max = Math.max(...bars)

        setCtx(ctx)
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        for (let i = 0; i < bars.length; i++) {
            locationMarker += spaceBetweenBars
            const x = locationMarker + i * barWidth
            const y = bottom - bars[i] * 300 / max

            let gradient = ctx.createLinearGradient(0, 350, 0, 150)
            gradient.addColorStop(0, "lightblue")
            gradient.addColorStop(1, "darkblue")
            ctx.fillStyle = gradient
            ctx.fillRect(x, y, barWidth, bars[i] * 300 / max)
            ctx.fillStyle = "black"
            ctx.fillRect(x, y, barWidth, 2)
            ctx.fillText(bars[i], x - inset, bottom - 10 - bars[i] * 300 / max)

            if (sorting)
                ctx.fillText("i", iIndexXPos, 420)
        };
    }

    const handleAdd10 = () => {
        const start = bars.length
        let end = start + 10

        if (end > 50)
            end = 50

        if (start < 50) {
            for (let i = start; i < end; i++) {
                let newBar = Math.ceil(Math.random() * 99)
                newBar = validateInput(newBar)

                setBars(bars => [...bars, newBar])
            }
        }
    }

    function validateInput(input) {
        let value = 0

        if (input < 1)
            value = 1
        else if (input > 99)
            value = 99
        else
            value = input

        return value
    }

    const clearBars = () => {
        setBars([])
    }

    const handleSort = () => {
        setTimerVisible(true)
        setSorting(true)
    }

    const handleStopTimer = () => {
        setSorting(false)
        setTimerVisible(false)
    }

    useEffect(() => {
        let interval
        let barToCheck = 1

        if (sorting) {
            interval = setInterval(() => { insertionSort() }, 500)

            const insertionSort = () => {
                if (barToCheck < bars.length) {
                    for (var k = 0; k < barToCheck; k++) {
                        if (bars[barToCheck] < bars[k]) {
                            moveBars(k)
                            return
                        }
                    }

                    barToCheck += 1
                    locationMarker = 0
                    iIndexXPos += (spaceBetweenBars + barWidth);

                    draw(ctx)
                } else {
                    setSorting(sorting => !sorting)
                }
            }

            const moveBars = (barToMove) => {
                for (let m = barToCheck; m > barToMove; m--) {
                    setBars(bars => {
                        const tempBar = bars[m]
                        bars[m] = bars[m - 1]
                        bars[m - 1] = tempBar

                        return bars
                    })
                }
            }
        }

        return () => clearInterval(interval)
    }, [sorting])

    return (
        <div className={styles.wrapper}>
            {!timerVisible &&
                <div className={styles.center}>
                    <Button className={styles.button}
                        variant="contained"
                        color="primary"
                        onClick={handleSort}
                        disabled={bars.length === 0}>Sort</Button>
                    <Button className={styles.button}
                        variant="contained"
                        color="primary"
                        onClick={handleAdd10}>Add More Items</Button>
                    <Button className={styles.button}
                        variant="contained"
                        color="primary"
                        onClick={clearBars}
                        disabled={bars.length === 0}>Clear</Button>
                </div>
            }
            {timerVisible &&
                <>
                    <div className={styles.center}>
                        <Button className={styles.button}
                            variant="contained"
                            color="primary"
                            onClick={handleStopTimer}
                            disabled={sorting}>
                            Start over
                        </Button>
                    </div>
                    <div className={styles.timer}>
                        <Timer sorting={sorting} />
                    </div>
                </>
            }
            <Canvas className={styles.canvas} draw={draw} height="500" width="1108" />
        </div>
    )
}

export default InsertSortAnimation
