// extracted by mini-css-extract-plugin
export var noResultsWrapper = "search-module--noResultsWrapper--2f0f8";
export var resultContainer = "search-module--resultContainer--ee69f";
export var resultLink = "search-module--resultLink--b7d0f";
export var resultListItem = "search-module--resultListItem--1b00b";
export var results = "search-module--results--a1256";
export var resultsWrapper = "search-module--resultsWrapper--b2fc0";
export var searchContainer = "search-module--searchContainer--5cde7";
export var searchIconWrapper = "search-module--searchIconWrapper--295ff";
export var searchInput = "search-module--searchInput--8d74f";
export var searchInputActive = "search-module--searchInputActive--ab992";