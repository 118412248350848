/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AppBar, IconButton, Toolbar, Typography, List, ListItemText, ListItemButton } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "gatsby"
import { useFlexSearch } from 'react-use-flexsearch';
import SearchBar from './search'

const AppBarStyled = props => {
  const [searchQuery, setSearchQuery] = useState('');
  const data = useStaticQuery(graphql `{
      localSearchPages {
        index
        store
      }
    }
  `)
  const {index, store} = data.localSearchPages;
  const results = useFlexSearch(searchQuery, index, store);

  return (
    <AppBar position="fixed" className="appBar">
      <Toolbar className="toolbar" role="toolbar">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => {props.handleDrawerToggle() }}
          className="menuButton menu-button"
          size="large">
          <MenuIcon />
        </IconButton>
        <Typography
          className="siteTitle site-title"
          component={Link}
          to="/"
          variant="subtitle2"
          noWrap
        >
          TecKno
        </Typography>
        <div className="desktopMenu" role="navigation">
          <List className="linkList">
            {props.navData.content.map((data, index) => (
                <li key={`list_item_${index}`}>
                  <ListItemButton
                    component={Link} to={data.url}
                    activeClassName="activeLink"
                    focusVisibleClassName="buttonOnFocus"
                    data-metrics={`{"btnname":"${data.name.toLowerCase()}"}`}>
                    <ListItemText primary={data.name} />
                  </ListItemButton>
                </li>
            ))}
          </List>
        </div>

        <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            results={results}
        />
      </Toolbar>
    </AppBar>
  );
}

export default AppBarStyled
