import React from 'react'
import { ButtonBase } from '@mui/material'

const styles = {
    maxWidth: '700px', 
    width: '70%'
}

const collapsedStyles = {
    maxWidth: '700px', 
    width: '70%',
    visibility: 'hidden',
    display: 'none'
}

function clickExpand() {
    document.getElementById('collapsedImage').style.visibility='hidden'
    document.getElementById('collapsedImage').style.display='none'
    document.getElementById('image').style.visibility='visible'
    document.getElementById('image').style.display='block'
}

function clickCollapse() {
    document.getElementById('collapsedImage').style.visibility='visible'
    document.getElementById('collapsedImage').style.display='block'
    document.getElementById('image').style.visibility='collapse'
    document.getElementById('image').style.display='none'
}

function CollapsibleImage({image, collapsedImage}) {
    return (
        <>
            <ButtonBase onClick={clickExpand}>
                <img id="collapsedImage" src={collapsedImage} alt="" style={styles} />
            </ButtonBase>
            <ButtonBase onClick={clickCollapse}>
                <img id="image" src={image} alt="" style={collapsedStyles} />
            </ButtonBase>
        </>
    )
}

export default CollapsibleImage