/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from "react"
import { Link } from "gatsby"
import { List, ListItemText, Drawer, Divider, IconButton, ListItemButton } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';
import * as classes from '../styles/app-nav-mobile.module.scss';

export const MobileNav = props => {
  const handleLinkClick = () => {
    props.handleDrawerToggle()
  }

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => { handleLinkClick() }}
        className={`${classes.menuButton} close-button`}
        size="large">
          <ClearIcon />
      </IconButton>
      <div className={classes.drawerContainer}>
        <List>
        {props.navData.content.map((data, index) => (
          <li key={`list_item_${index}`} className={classes.navLink} >
            <Divider/>
            <ListItemButton onClick={() => { handleLinkClick() }} component={Link} to={data.url} data-metrics={`{"btnname":"${data.name.toLowerCase()}"}`}>
              <ListItemText primary={data.name}/>
            </ListItemButton>
          </li>
        ))}
        </List>
      </div>
    </div>
  );
}

const DrawerSideNav = props => {
  const { handleDrawerToggle, mobileOpen, navData } = props

  return (
    <div className={classes.navBg}>
      <nav className="drawer">
        <Drawer
          className="drawer"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{paper: classes.drawerPaper}}
          ModalProps={{keepMounted: true,}}
        >
          {navData && <MobileNav handleDrawerToggle={handleDrawerToggle} navData={navData}/>}
        </Drawer>
      </nav>
    </div>
  )
}

export default DrawerSideNav
