/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from "react"
import classNames from "classnames";

class Box extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            highlight: props.isHighlighted,
            isInFocus: props.isInFocus,
            inputValue: ''
        };

        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentWillReceiveProps(newProps, nextContext) {
        this.setState({
            highlight: newProps.isHighlighted,
            isInFocus: newProps.isInFocus
        });
    }

    componentDidUpdate() {
        if (this.state.isInFocus) {
            this.textInput.focus();
        }
    }

    handleFocus(event) {
        this.props.setActiveClue(this.props.boxClues);

        let boxesToHighlight = [];

        for (const clue of this.props.boxClues) {
            boxesToHighlight = boxesToHighlight.concat(this.props.allClues[clue].boxes);
        }

        this.props.setActiveClueBoxes(boxesToHighlight);
        this.props.setBoxInFocus(event.target.id);
    }

    handleKeyDown(event) {
        // Letter A has ASCII code of '65'

        const row = this.props.id.slice(0, 1).charCodeAt(0)
        const column = parseInt(this.props.id.slice(-1))

        switch (event.keyCode) {
            case 37:
                const leftCol = column > 1 ? column - 1 : this.props.cols
                return this.moveBox(row, leftCol)
            case 38:
                const upRow = row < 66 ? 64 + this.props.rows : row - 1
                return this.moveBox(upRow, column)
            case 39:
                const rightCol = column === this.props.cols ? 1 : column + 1
                return this.moveBox(row, rightCol)
            case 40:
                const downRow = row < 64 + this.props.rows ? row + 1 : 65
                return this.moveBox(downRow, column)
        }
    }

    moveBox(row, column) {
        const boxId = String.fromCharCode(row) + column.toString()
        this.props.setBoxInFocus(boxId)
    }

    updateBoxValue(value) {
        this.setState({ inputValue: value })
        this.props.validateInput(this.props.id, this.props.letter, this.props.boxClues, this.props.letter === value || value === '')
    }

    render() {
        const { label, letter } = this.props;

        return (
            <div className={`box ${!letter ? 'blank' : ''}`}>
                {label && <span className="box-label">{this.props.label}</span>}

                {letter
                    ? <input type="text"
                        maxLength="1"
                        className={classNames('box-input', {
                            highlight: this.state.highlight,
                            incorrect: this.props.checkAnswers && ![letter, ''].includes(this.state.inputValue),
                            correct: this.props.checkAnswers && this.state.inputValue === letter
                        })}
                        onFocus={this.handleFocus}
                        onKeyDown={this.handleKeyDown}
                        onChange={(e) => this.updateBoxValue(e.target.value.toUpperCase())}
                        ref={(input) => { this.textInput = input }} />
                    : <input type="text"
                        maxLength="0"
                        onKeyDown={this.handleKeyDown}
                        className={`box-input blank`}
                        ref={(input) => { this.textInput = input }} />
                }
            </div>
        );
    }
}

export default Box
