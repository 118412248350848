/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from "react"

import Box from "./box"
import Clues from "./clues"
import "./crossword.scss"
import { Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

class Crossword extends React.Component {
  constructor(props) {
    super(props)
    const { clueData, gridData } = props

    this.state = {
      grid: gridData,
      clues: clueData,
      activeClueBoxes: clueData["Dn1"].boxes,
      activeClue: ["Dn1"],
      boxInFocus: clueData["Dn1"].boxes[0],
      showAnswers: false,
      checkAnswers: false,
      validatedInput: [],
      correctAnswers: [],
      incorrectAnswers: [],
    }

    this.setActiveClueBoxes = this.setActiveClueBoxes.bind(this)
    this.setActiveClue = this.setActiveClue.bind(this)
    this.setBoxInFocus = this.setBoxInFocus.bind(this)
    this.validateInput = this.validateInput.bind(this)
  }

  setActiveClueBoxes(boxes) {
    this.setState({ activeClueBoxes: boxes })
  }

  setActiveClue(clue) {
    this.setState({ activeClue: clue })
  }

  setBoxInFocus(box) {
    this.setState({ boxInFocus: box })
  }

  toggleAnswers() {
    this.setState({ showAnswers: !this.state.showAnswers })
  }

  validateInput(id, letter, clues, isCorrect) {
    this.setState({ checkAnswers: false })
    const validatedInput = [...this.state.validatedInput]

    clues.forEach(clue => {
      const index = this.state.validatedInput.findIndex(
        value => value.id === id && value.clue === clue
      )

      if (index > -1) {
        validatedInput[index].isCorrect = isCorrect
      } else {
        validatedInput.push({ id, letter, clue, isCorrect })
      }
    })

    this.setState({ validatedInput })
  }

  checkAnswers() {
    this.setState({ checkAnswers: true })

    const incorrectInput = this.state.validatedInput.filter(
      input => !input.isCorrect
    )
    const correctAnswers = this.state.validatedInput
      .filter(
        input =>
          input.isCorrect &&
          incorrectInput.findIndex(
            incAnswer =>
              incAnswer.id === input.id && incAnswer.clue === input.clue
          ) < 0
      )
      .map(input => input.clue)
    const incorrectAnswers = incorrectInput.map(input => input.clue)

    this.setState({
      incorrectAnswers: [...new Set(incorrectAnswers)],
      correctAnswers: [...new Set(correctAnswers)],
    })
  }

  renderResults() {
    if (this.state.checkAnswers && this.state.incorrectAnswers.length < 1) {
      return <div className="noErrors">
        No incorrect answers found <CheckCircleOutlineIcon className="correctIcon" style={{ color: "green" }} />
      </div>
    }

    if (this.state.checkAnswers && this.state.incorrectAnswers.length > 0) {
      return (
        <div className="answerCheck">
          Incorrect Answers:
          <ul>
            {this.state.incorrectAnswers.map((answer, index) => {
              return answer.includes("Dn") ? (
                <li key={index}>{answer.slice(2)} Down</li>
              ) : (
                <li key={index}>{answer.slice(2)} Across</li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  render() {
    const { rows, cols } = this.props

    return (
      <div>
        {this.props.title && <h5>{this.props.title}</h5>}
        <div className="crossword">
          <div className="clue-lists">
            <Clues
              clues={this.state.clues}
              setActiveClueBoxes={this.setActiveClueBoxes}
              activeClue={this.state.activeClue}
              setActiveClue={this.setActiveClue}
              setBoxInFocus={this.setBoxInFocus}
            />

            <div className="answerButtons">
              <Button
                variant="contained"
                size="small"
                color="primary"
                className="check-btn"
                onClick={() => this.checkAnswers()}
              >
                Check Answers
              </Button>
              <Button
                variant="contained"
                size="small"
                className="show-btn"
                onClick={() => this.toggleAnswers()}
              >
                Show Answers
              </Button>
            </div>

            {this.renderResults()}

            {this.state.showAnswers && (
              <ol>
                {Object.entries(this.state.clues).map(([key, clue]) => (
                  <li key={key}>{clue.answer}</li>
                ))}
              </ol>
            )}
          </div>

          <div
            className="crossword-board"
            style={{
              width: `calc(50px * ${cols})`,
              height: `calc(50px * ${rows})`,
            }}
          >
            {this.state.grid.map(box => {
              const { id, letter, clues, label } = box
              return (
                <Box
                  key={id}
                  id={id}
                  letter={letter}
                  boxClues={clues}
                  label={label}
                  cols={cols}
                  rows={rows}
                  allClues={this.state.clues}
                  isHighlighted={this.state.activeClueBoxes.indexOf(id) > -1}
                  setActiveClueBoxes={this.setActiveClueBoxes}
                  setActiveClue={this.setActiveClue}
                  setBoxInFocus={this.setBoxInFocus}
                  validateInput={this.validateInput}
                  checkAnswers={this.state.checkAnswers}
                  isInFocus={this.state.boxInFocus === id}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Crossword
