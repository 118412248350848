/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import React, { useEffect, useState } from 'react'
import { Typography } from "@mui/material";

const Timer = ({ sorting }) => {
    const [time, setTime] = useState(0)

    useEffect(() => {
        let interval

        if (sorting) {
            interval = setInterval(() => {
                setTime(prevTime => prevTime + 10)
            }, 10)
        }

        return () => clearInterval(interval)
    }, [sorting])

    return (
        <>
            <Typography variant="subtitle2">
                <span>{('0' + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                <span>{('0' + Math.floor((time / 1000) % 60)).slice(-2)}:</span>
                <span>{('0' + ((time / 10) % 100)).slice(-2)}</span>
            </Typography>
        </>
    )
}

export default Timer