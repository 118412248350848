/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from 'react';
import useCanvas from './use-canvas';

const Canvas = props => {
    const { draw, ...rest } = props
    const canvasRef = useCanvas(draw)

    return <canvas ref={canvasRef} {...rest} />
}

export default Canvas