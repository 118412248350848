import React from "react";
import {
  Box,
  Zoom,
  Fab,
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

const ScrollTopButton = (props) => {
    const trigger = useScrollTrigger({
      target: props.window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });
 
    const scrollToTop = () => {window.scrollTo({ top: 0, behavior: "smooth" })};
 
   return (
       <Zoom in={trigger}>
         <Box
           role="presentation"
           className="scroll-top-button"
           sx={{
             position: "fixed",
             bottom: 32,
             right: 32,
             zIndex: 1,
           }}
         >
           <Fab
             onClick={scrollToTop}
             color="primary"
             size="small"
             className="scroll-top-btn"
             aria-label="scroll back to top"
           >
             <KeyboardArrowUp />
           </Fab>
         </Box>
       </Zoom>
   )
 }

 export default ScrollTopButton;