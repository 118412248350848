/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { googlecode } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Box } from '@mui/material'
import * as styles from '../../styles/code-block.module.scss';


const CodeBlock = props => {
  return (
    <>
      <Box className={styles.code}>
        <SyntaxHighlighter
          language="python"
          style={googlecode}
          className={styles.syntaxHighlighter}
        >
          {props.code}
        </SyntaxHighlighter>
      </Box>
      {props.result && (
        <Box className={styles.result} p={1} boxShadow={1}>
          {props.result}
        </Box>
      )}
    </>
  )
}

export default CodeBlock
