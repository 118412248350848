/*
 * Copyright '2021' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import React from "react"
import { Toolbar, Container, ButtonBase, Hidden } from "@mui/material"
import { Link } from "gatsby";
import * as styles from '../styles/footer.module.scss';

export default function Footer() {
  const currentYear = new Date().getFullYear()

  return (
      <footer position="static" className={styles.footerStyle}>
        <Container className={styles.footerContentContainer}>
        <Hidden smDown>
          <img src="/footer/footerBg.svg" style={{visibility: 'hidden', width: 'inherit'}}/>
          </Hidden>
          <Hidden smUp>
          <img src="/footer/footerBg.svg" style={{ visibility: 'hidden' }} />
        </Hidden>
          <div className={styles.footerContent}>
            <div className={styles.footerText}>
              <ButtonBase component={Link} to="/">
                <h2 className={styles.footerTitle}>TecKno</h2>
              </ButtonBase>
              <p className={styles.footerRights}>© {currentYear} Dell Inc. or its subsidaries. All Rights Reserved.</p>
            </div>
            <Toolbar className={styles.footerToolbar}>
              <ButtonBase component={Link} className={styles.footerLink} to="/courses/">
                Courses
              </ButtonBase>
              <ButtonBase component={Link} className={styles.footerLink} to="/games/">
                Games
              </ButtonBase>
              <ButtonBase component={Link} className={styles.footerLink} to="/careers/">
                Careers
              </ButtonBase>
              <ButtonBase component={Link} className={styles.footerLink} to="/contact">
                Contact Us
              </ButtonBase>
            </Toolbar>
          </div>
        </Container>
        <div className="dellmetrics-teckno"></div>
      </footer>
  )
}
