import React, { useState } from "react"
import "./binaryConversionGame.scss"
import { Button, TextField } from '@mui/material'


function BinaryConversionGame() {

    const questionsList = {
        hexToBinary: {
            '7': '0111',
            'B8': '10111000',
            '1F44D': '00011111010001001101',
            'ABC': '101010111100',
            '15D': '000101011101'
        },
        binaryToHex: {
            '101000101110': 'A2E',
            '1111': 'F',
            '001011011111': '2DF',
            '10010110': '96',
            '11001011': 'CB',
        },
        decimalToBinary: {
            '10': '1010',
            '5': '101',
            '25': '11001',
            '700': '1010111100',
            '8': '1000',
        },
        binaryToDecimal: {
            '10': '2',
            '101': '5',
            '101001': '41',
            '1101101': '109',
            '10001': '17',
        }
    }

    const [ conversionType, setConversionType ] = useState(questionsList.hexToBinary)

    const [ question, setQuestion ] = useState([Object.keys(conversionType)[1], Object.values(conversionType)[1]])

    const [ answer, setAnswer ] = useState('')
    
    const [ feedback, setFeedback ] = useState('')

    function pickRandomQuestion(conversionType) {
        let random = (Object.keys(conversionType).length * Math.random() << 0)
        setQuestion([Object.keys(conversionType)[random], Object.values(conversionType)[random]])
    }

    function handleCheckAnswer() {
        let comparison = answer.localeCompare(question[1], 'en', { sensitivity: 'base' })
        if (comparison === 0) {
            setFeedback('Correct!')
            document.getElementById('checkAnswerButton').style.display='none'
            document.getElementById('tryAnotherButton').style.display='block'
        } else {
            setFeedback('Oops! Try again')
        }
    }

    function handleTryAnother() {
        setFeedback('')
        document.getElementById('checkAnswerButton').style.display='block'
        document.getElementById('tryAnotherButton').style.display='none'
        setAnswer('')
        pickRandomQuestion(conversionType)
    }

    function handleConversionSelection(event) {
        let { value } = event.target
        setConversionType(questionsList[value])
        pickRandomQuestion(questionsList[value])
        setFeedback('')
        setAnswer('')
    }

    return (
        <div className="binary-game">
            <div className="question-container">
                <div className="conversion-type-list" onChange={handleConversionSelection}>
                    <label><input type="radio" value="hexToBinary" name="conversion" defaultChecked />Hexadecimal to binary</label>
                    <label><input type="radio" value="binaryToHex" name="conversion" />Binary to hexadecimal</label>
                    <label><input type="radio" value="decimalToBinary" name="conversion" />Decimal to binary</label>
                    <label><input type="radio" value="binaryToDecimal" name="conversion" />Binary to decimal</label>
                </div>
                <p className="question">Convert {question[0]}</p>
                <TextField variant="standard" placeholder="Type your answer here" type="text" name="answerField" value={answer} onChange={event => setAnswer(event.target.value)}/>
                <p id="feedbackText">{feedback}</p>
                <Button variant="contained" id="checkAnswerButton" onClick={handleCheckAnswer}>Check Answer</Button>
                <Button variant="contained" id="tryAnotherButton" onClick={handleTryAnother} >Try Another</Button>
            </div>
        </div>
    )
}

export default BinaryConversionGame