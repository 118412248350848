import React, { useState } from "react";
import { Button } from "@mui/material";

function ToggleTrinketButton(props) {
    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {setIsVisible(!isVisible)}

    return (
        <>
            <Button className="toggle-trinket-btn" variant="contained" onClick={toggleVisibility}>Build your own code</Button>
            <br/>
            <br/>
            { isVisible && <iframe id="trinket3"
                    src={props.trinketUrl} 
                    width="100%" 
                    height="356" 
                    frameborder="0" 
                    marginwidth="0" 
                    marginheight="0" 
                    allowfullscreen>
                </iframe> 
            }
        </>
    )
}
    export default ToggleTrinketButton;